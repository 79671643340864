<template>
    <div class="w100 bs flex bgc_fff" style="height: 740px;">
        <div class="shrink_0 slid_left">
            <div class="flex align-center w100 bs w100 justify-between"
                style="padding: 0 16px;height: 56px;border-bottom: 1px solid rgb(240, 242, 245);">
                <div class="text-16 c_262626 shrink_0">消息列表</div>
                <el-input class="slid_left_input bs" size="small" prefix-icon="el-icon-search" v-model="megInput"
                    placeholder="搜姓名" clearable />
            </div>
            <div class="peWrap w100 bs">
                <el-scrollbar height="684px">
                    <ul v-infinite-scroll="load" class="infinite-list">
                        <li v-for="(item, index) in peList" :key="index" class="infinite-list-item w100 bs"
                            :class="peIndex == index ? 'list-active' : ''" @click="peClick(index)">
                            <img :src="item.seekerPhoto" class="i-l-image shrink_0" alt="">
                            <div class="i-l-box flex flex-direction text-14 justify-around flex-sub">
                                <div class="flex align-center justify-between">
                                    <div class="c_262626 flex align-center">
                                        <div>{{ item.seekerName }}</div>
                                    </div>
                                    <div class="c_a6a6a6 flex align-center">
                                        <div v-if="item.jobName" class="text-12 text-bold i-l-job">{{ item.jobName }}
                                        </div> {{ item.updateDate }}
                                    </div>
                                </div>
                                <div class="c_a6a6a6">{{ item.msgContent }}</div>

                            </div>
                        </li>
                    </ul>
                </el-scrollbar>
            </div>
        </div>

        <div class="flex-sub">
            <div v-if="peIndex == -1" class="w100 flex align-center justify-center flex-direction"
                style="height: 100%;">
                <img src="@/assets/icon_news_01.png" style="display: block;width: 215px;height: 191px;">
                <div class="text-14 c_595959" style="margin-top:17px;">选择左侧列表候选人及时沟通</div>
            </div>
            <div class="w100 bs" v-if="peIndex != -1">
                <div class="bs w100 i-tabs-box flex align-center">
                    <el-tabs v-model="activeName" class="i-tabs bs shrink_0 w100" @tab-click="handleClick">
                        <el-tab-pane label="聊天" name="first">
                            <div class="w100 bs">
                                <div class="i-r-p text-14">
                                    <div class="">
                                        <div class="css-ngy7vp eg6e0mz4" style="flex-grow: 0;">
                                            <span class="text-18 c_262626 text-bold">{{ peList[peIndex].seekerName
                                            }}</span>
                                            <span class="text-14 c_262626" v-if="chatInfo.ext&&chatInfo.ext.resumeId" style="margin-left: 10px;">{{rInfo.ext?.age}}岁 | {{ rInfo.ext ? rInfo.ext.liveCityRemarks : '' }} | {{ rInfo.ext?.workYears }}年经验</span>
                                        </div>
                                    </div>
                                    <div class="flex align-center justify-between c_262626 text-14"
                                        style="margin-top: 12px;">
                                        <span>
                                            <!-- 深圳市瀚适医药有限公司 |
                                            销售经理 -->
                                        </span>
                                        <div class="flex align-center">
                                            <span class="css-ye3j87 eg6e0mz3">当前沟通职位：</span>
                                            <div style="color:#40a9ff;" class="cur-p" @click="setbottom">
                                                {{ chatInfo.jobName }}
                                                <div class="el-icon-Switch"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- 1-文本，2-图片，3-简历，4-职位，5-邀请投递简历，6-邀请交换电话，7-提示消息 -->
                                <div class="w100 bs" style="height: 333px;">
                                    <el-scrollbar height="333px" ref="scrollbarRef" always @scroll="scrollnew">
                                        <ul class="right-infinite-list" ref="scrollbarUl">
                                            <li v-for="(item, index) in mesList" :key="index" class="w100 bs"
                                                style="padding-bottom:24px;">
                                                <div class="w100 bs" v-if="item.type == '1'">
                                                    <div class="flex" v-if="item.ext.sender !== 'HR'">
                                                        <img :src="item.ext.userPhoto" class="list-head-img shrink_0">
                                                        <div class="list-box">
                                                            <div class="list-text text-14 c_262626">{{ item.msg }}</div>
                                                        </div>
                                                    </div>
                                                    <div class="flex justify-end" v-else>
                                                        <div class="list-box" style="margin: 0 10px 0 0 ;">
                                                            <div class="list-text text-14 c_262626">
                                                                {{ item.msg }}</div>
                                                        </div>
                                                        <img :src="item.ext.userPhoto" class="list-head-img shrink_0">
                                                    </div>
                                                </div>
                                                <div class="w100 bs flex align-center justify-center text-14 "
                                                    v-if="item.type == '3'">
                                                    <div
                                                        style="width:300px;border: 1px solid rgb(242, 244, 246);line-height: 45px;text-align: center;">
                                                        <div style="border-bottom: 1px solid rgb(240, 242, 245);">
                                                            简历已发送给您</div>
                                                        <!-- <div class="c_1890ff cur-p">点击查看详情</div> -->
                                                    </div>
                                                </div>
                                                <div class="w100 bs" v-if="item.type == '4'">
                                                    <div class="flex cur-p" v-if="item.ext.sender !== 'HR'">
                                                        <img :src="item.ext.userPhoto" class="list-head-img shrink_0">
                                                        <div class="w100 bs"
                                                            style="width:340px;padding: 15px 16px 18px;margin-left: 10px;border: 1px solid rgb(240, 242, 245);">
                                                            <div class="w100 flex justify-between align-center">
                                                                <div class="text-16">技术开发</div>
                                                                <div style="color: rgb(247, 95, 53);" classs="text-14">
                                                                    10-12k/月</div>
                                                            </div>
                                                            <div class="flex">
                                                                <div class="css-weqeq text-14">深圳</div>
                                                            </div>
                                                            <div class="text-14" style="color: rgb(89, 89, 89);">
                                                                深圳市太古神族科技有限公司</div>
                                                        </div>
                                                    </div>
                                                    <div class="flex justify-end cur-p" v-else>
                                                        <div class="w100 bs"
                                                            style="width:340px;padding: 15px 16px 18px;margin-right: 10px;border: 1px solid rgb(240, 242, 245);">
                                                            <div class="w100 flex justify-between align-center">
                                                                <div class="text-16">技术开发</div>
                                                                <div style="color: rgb(247, 95, 53);" classs="text-14">
                                                                    10-12k/月</div>
                                                            </div>
                                                            <div class="flex">
                                                                <div class="css-weqeq text-14">深圳</div>
                                                            </div>
                                                            <div class="text-14" style="color: rgb(89, 89, 89);">
                                                                深圳市太古神族科技有限公司</div>
                                                        </div>
                                                        <img :src="item.ext.userPhoto" class="list-head-img shrink_0">
                                                    </div>
                                                </div>
                                                <div class="w100 bs flex justify-center text-14" style="color:#666;"
                                                    v-if="item.type == '7'">
                                                    {{ item.msg }}
                                                </div>
                                            </li>
                                            <!-- <li class="w100 bs"
                                                style="padding-bottom:24px;">
                                               
                                            </li>   -->
                                        </ul>
                                    </el-scrollbar>
                                </div>

                                <div class="w100 bs i-end">
                                    <div class="i-end-btn">
                                        <el-button type="primary" size="small" round @click="JobApplyClcik(5)">邀请投递
                                        </el-button>
                                        <el-button type="primary" size="small"
                                            :disabled="chatInfo.seekerExchangePhone == 'Y' ? true : false" round
                                            @click="JobApplyClcik(6)">{{ chatInfo.seekerExchangePhone == 'Y' ? '已交换' :
                                                    '交换电话'
                                            }}
                                        </el-button>
                                    </div>
                                    <el-input @keyup.enter="submitY" v-model="textarea" :rows="4" type="textarea"
                                        placeholder="请输入消息内容" />
                                    <div class="i-end-comit flex align-center justify-end">
                                        <!-- <div style="font-size: 13px;color: #a6a6a6;margin-right: 24px;">Enter发送，Ctrl+Enter换行</div> -->
                                        <el-button type="primary" @click="sendMessage"
                                            :disabled="textarea ? false : true">发送</el-button>
                                    </div>
                                </div>

                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="在线简历" name="second" v-if="chatInfo.ext&&chatInfo.ext.resumeId">
                            <div class="w100 bs ">
                                <el-scrollbar height="684px">
                                    <div class="w100 bs " style="padding: 24px;">
                                        <div class="flex pb_24">
                                            <div class="shrink_0"
                                                style="display: block;width: 100px;height: 112px;margin-right: 14px;">
                                                <el-image style="width: 100px; height: 100px"
                                                    :src="rInfo.ext ? rInfo.ext.userPhoto : ''" fit="contain" />
                                            </div>
                                            <div class="flex-sub">
                                                <div class="w100 bs flex align-center justify-between mb_4"
                                                    style="height: 37px;">
                                                    <div class="flex align-center">
                                                        <div class="text-24 resume_name">
                                                            {{ rInfo.name }}
                                                        </div>
                                                        <!-- <div class="resume_label">离职，随时可以面试</div> -->
                                                    </div>
                                                    <div class="flex align-center c_1890ff text-14">
                                                        <div class="flex align-center">
                                                            <!-- <div class="el-icon-download text-16"></div>
                                                            <span style="margin-left: 10px;">保存到本地</span> -->
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="c_272727 text-14 mb_4" style="line-height: 22px;">
                                                    <span class="css-1cgrbob eyp4si65">{{ rInfo.ext?.age }}岁</span>
                                                    <span class="css-1cgrbob eyp4si65">{{ rInfo.ext ?
                                                            rInfo.ext.liveCityRemarks : ''
                                                    }}</span>
                                                    <span class="css-1cgrbob eyp4si65">{{ rInfo.ext?.workYears
                                                    }}年经验</span>
                                                </div>
                                                <div class="c_272727 text-14 mb_4" style="line-height: 22px;"
                                                    v-if="rInfo.ext && rInfo.ext.resumeWorkList && rInfo.ext.resumeWorkList.length > 0">
                                                    <span class="css-1cgrbob eyp4si65">{{
                                                            rInfo.ext.resumeWorkList[0].company
                                                    }}</span>
                                                    <span class="css-1cgrbob eyp4si65">{{
                                                            rInfo.ext.resumeWorkList[0].job
                                                    }}</span>
                                                </div>
                                                <div class="c_272727 text-14 flex align-center" v-if="rInfo.ext">
                                                    <span class="css-1cgrbob eyp4si65 flex align-center">
                                                        <span class="el-icon-mobile-phone c_1890ff text-16"></span>{{peList[peIndex].seekerExchangePhone=='Y'?rInfo.ext.mobile:'--'}}
                                                    </span>
                                                    <span class="css-1cgrbob eyp4si65 flex align-center">
                                                        <span class="el-icon-message c_1890ff text-16"></span>{{peList[peIndex].seekerExchangePhone=='Y'?rInfo.ext.email:'--'}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="df_intention w100 bs">
                                            <div class="w100 flex align-center" style="height: 30px;line-height: 30px;">
                                                <img src="@/assets/icon_jl_01.png" class="df_icon" alt="">
                                                <div class="text-18 text-bold c_101010">求职意向</div>
                                            </div>
                                            <div class="w100 bs df_con_ul c_272727 text-14">
                                                <div class="w100 df_con_li"
                                                    v-for="(item, index) in rInfo.resumeIntendList" :key="index">
                                                    <div class="mb_20 w100 flex">
                                                        <div class="w50">期望职位：{{ item.ext.categoryName }}</div>
                                                        <div class="w50">期望薪资：{{ item.salaryMin / 1000 }}-{{
                                                                item.salaryMax / 1000
                                                        }}K</div>
                                                    </div>
                                                    <div class="w100 flex">
                                                        <div class="w50">工作地点：{{ item.ext.liveCityName }}</div>
                                                        <div class="w50">工作类型：{{ nlist[item.nature - 1] }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="df_intention w100 bs">
                                            <div class="w100 flex align-center" style="height: 30px;line-height: 30px;">
                                                <img src="@/assets/icon_jl_02.png" class="df_icon" alt="">
                                                <div class="text-18 text-bold c_101010">工作经历</div>
                                            </div>
                                            <div class="w100 bs df_con_ul c_272727 text-14">
                                                <div class="w100 bs css-rktgro"
                                                    v-for="(item, index) in rInfo.resumeWorkList" :key="index">
                                                    <div class="flex align-center c_101010 text-bold">
                                                        <div class="text-16 mr_30">{{ item.company }}</div>
                                                        <div class="mr_30">{{ item.job }}</div>
                                                        <div>{{ item.startTime }}&nbsp;-&nbsp;{{ item.endTime }}</div>
                                                    </div>
                                                    <div class="mt_20 w100 bs flex">
                                                        <div class="shirnk_0">职位描述：</div>
                                                        <div class="flex-sub">{{ item.description }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="df_intention w100 bs">
                                            <div class="w100 flex align-center" style="height: 30px;line-height: 30px;">
                                                <img src="@/assets/icon_jl_03.png" class="df_icon" alt="">
                                                <div class="text-18 text-bold c_101010">项目经验</div>
                                            </div>
                                            <div class="w100 bs df_con_ul c_272727 text-14">
                                                <div class="w100 bs css-rktgro"
                                                    v-for="(item, index) in rInfo.resumeProjectList" :key="index">
                                                    <div class="flex align-center c_101010 text-bold">
                                                        <div class="text-16 mr_30">{{ item.name }}</div>
                                                        <div>{{ item.startTime }}&nbsp;-&nbsp;{{ item.endTime }}</div>
                                                    </div>
                                                    <div class="mt_20 w100 bs flex">
                                                        <div class="shirnk_0">项目描述：</div>
                                                        <div class="flex-sub">{{ item.description }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="df_intention w100 bs">
                                            <div class="w100 flex align-center" style="height: 30px;line-height: 30px;">
                                                <img src="@/assets/icon_jl_04.png" class="df_icon" alt="">
                                                <div class="text-18 text-bold c_101010">教育经历</div>
                                            </div>
                                            <div class="w100 bs df_con_ul c_272727 text-14">
                                                <div class="w100 bs css-rktgro"
                                                    v-for="(item, index) in rInfo.resumeEducationList" :key="index">
                                                    <div class="flex align-center c_101010 text-bold">
                                                        <div class="text-16 mr_30">{{ item.name }}</div>
                                                        <div class="mr_30">{{ item.major }}</div>
                                                        <div>{{ item.startTime }}&nbsp;-&nbsp;{{ item.endTime }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </el-scrollbar>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { reactive, toRefs, defineComponent, ref, nextTick, onBeforeUnmount, watch } from 'vue';
import { ElMessage } from 'element-plus'
import router from "@/router/index";
import { listChatInfo, getSeekerResumeInfo, listChatMsg, sendChatMsg, loopChatMsg, saveJobApply, getChatInfo } from '@/util/apis'
export default defineComponent({
    name: "",
    setup: () => {
        const state = reactive({
            peList: [] as any,
            mesList: [] as any,
            peIndex: -1,
            activeName: 'first',
            udPeListLog: true,
            megInput: '',
            textarea: '',
            chatInfo: {} as any,
            rInfo: {} as any,
            nlist: ['全职', '兼职', '实习', '校招']
        })
        listChatInfo({}).then(res => {
            console.log('listChatInfo', res)
            res.data.forEach((element: any) => {
                element.updateDate = element.updateDate.substring(5, 10);
            });
            state.peList = res.data;
            if (router.currentRoute.value.params.id) {
                let fromIndex = state.peList.findIndex(((item: any) => item.id == router.currentRoute.value.params.id));
                fromIndex >= 0 ? peClick(fromIndex) : '';
            }
        })
        watch(() => state.megInput, (newValue, oldValue) => {
            console.log('watch 已触发', newValue)

        }
        )
        const scrollbarRef = ref(null);
        const scrollbarUl = ref(null);
        var loopTime = null as any;
        const peClick = (pI: number) => {
            state.peIndex = pI;

            getChatInfo({
                chatId: state.peList[pI].id
            }).then(res => {
                console.log('getChatInfo==', res)
                state.chatInfo = res.data;
                if (state.chatInfo.ext.resumeId) {
                    getSeekerResumeInfo({
                        id: state.chatInfo.ext.resumeId,
                    }).then(res => {
                        if (res.code == '0') {
                            res.data.resumeIntendList = res.data.ext.resumeIntendList;
                            res.data.resumeWorkList = res.data.ext.resumeWorkList;
                            res.data.resumeEducationList = res.data.ext.resumeEducationList;
                            res.data.resumeProjectList = res.data.ext.resumeProjectList;
                            state.rInfo = res.data
                        }
                    })
                }
                getMsg(); 

            })
        }

        const loopChatMsgSet = () => {
            clearTimeLoop();
            loopChatMsg({
                chatId: state.peList[state.peIndex].id,
                newestMsgId: state.mesList && state.mesList.length > 0 ? state.mesList[state.mesList.length - 1].id : 1
            }).then(res => {
                console.log('loopChatMsg==', res)
                if (res.data && res.data.length > 0) {
                    console.log('有新消息', res.data)
                    state.mesList = [...state.mesList, ...res.data];
                    nextTick(() => {
                        setbottom()
                    })
                }
                loopTime = setTimeout(() => {
                    loopChatMsgSet();
                }, 5000)
            }).catch(f => {
                loopTime = setTimeout(() => {
                    loopChatMsgSet();
                }, 5000)
            })
        }
        const clearTimeLoop = () => {
            if (loopTime) {
                clearInterval(loopTime);
                loopTime = null;
            }
        }
        onBeforeUnmount(() => {
            clearTimeLoop();
        })
        const handleClick = () => { }
        const getMsg = () => {
            listChatMsg({
                chatId: state.peList[state.peIndex].id,
                page: 1,
                pageSize: 20,
                mostFrontMsgId: ''
            }).then(res => {
                if (res.code == 0) {
                    state.mesList = res.data;
                    nextTick(() => {
                        setbottom();
                        state.udPeListLog = true;
                    })
                    loopChatMsgSet();
                }
            })
        }
        const scrollnew = (e: any) => {
            if (e.scrollTop < 10 && state.udPeListLog) {
                state.udPeListLog = false;
                let oldH = (scrollbarUl as any).value.offsetHeight;
                listChatMsg({
                    chatId: state.peList[state.peIndex].id,
                    page: 1,
                    pageSize: 20,
                    mostFrontMsgId: state.mesList.length > 0 ? state.mesList[0].id : ''
                }).then(res => {
                    if (res.code == 0) {
                        state.mesList = [...res.data, ...state.mesList];
                        state.udPeListLog = res.data.length >= 20 ? true : false;
                        nextTick(() => {
                            (scrollbarRef as any).value!.setScrollTop((scrollbarUl as any).value.offsetHeight - oldH);
                        })

                    }
                })
            }
        }

        const setbottom = () => {
            (scrollbarRef as any).value!.setScrollTop(999999999);
        }

        const sendMessage = () => {
            if (state.textarea) {
                sendChatMsg({
                    type: '1',
                    msgContent: state.textarea,
                    chatId: state.peList[state.peIndex].id,
                }).then(res => {
                    state.textarea = '';
                    clearTimeLoop();
                    loopChatMsgSet();
                })
            }
        }
        const submitY = () => {
            console.log('submitY', 1111)
        }
        const JobApplyClcik = (numType: number) => {
            sendChatMsg({
                type: numType,
                chatId: state.peList[state.peIndex].id,
                msgContent: numType == 5 ? '邀请投递简历' : '邀请交换电话'
            }).then(res => {
                clearTimeLoop();
                loopChatMsgSet();
            })
        }
        const load = () => { }
        return {
            ...toRefs(state), load, peClick, handleClick, scrollnew, setbottom, scrollbarRef, scrollbarUl, sendMessage, submitY, JobApplyClcik
        }
    }
})
</script>
<style scoped>
@import "./index.css";
</style>