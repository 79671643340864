
import { reactive, toRefs, defineComponent, ref, nextTick, onBeforeUnmount, watch } from 'vue';
import { ElMessage } from 'element-plus'
import router from "@/router/index";
import { listChatInfo, getSeekerResumeInfo, listChatMsg, sendChatMsg, loopChatMsg, saveJobApply, getChatInfo } from '@/util/apis'
export default defineComponent({
    name: "",
    setup: () => {
        const state = reactive({
            peList: [] as any,
            mesList: [] as any,
            peIndex: -1,
            activeName: 'first',
            udPeListLog: true,
            megInput: '',
            textarea: '',
            chatInfo: {} as any,
            rInfo: {} as any,
            nlist: ['全职', '兼职', '实习', '校招']
        })
        listChatInfo({}).then(res => {
            console.log('listChatInfo', res)
            res.data.forEach((element: any) => {
                element.updateDate = element.updateDate.substring(5, 10);
            });
            state.peList = res.data;
            if (router.currentRoute.value.params.id) {
                let fromIndex = state.peList.findIndex(((item: any) => item.id == router.currentRoute.value.params.id));
                fromIndex >= 0 ? peClick(fromIndex) : '';
            }
        })
        watch(() => state.megInput, (newValue, oldValue) => {
            console.log('watch 已触发', newValue)

        }
        )
        const scrollbarRef = ref(null);
        const scrollbarUl = ref(null);
        var loopTime = null as any;
        const peClick = (pI: number) => {
            state.peIndex = pI;

            getChatInfo({
                chatId: state.peList[pI].id
            }).then(res => {
                console.log('getChatInfo==', res)
                state.chatInfo = res.data;
                if (state.chatInfo.ext.resumeId) {
                    getSeekerResumeInfo({
                        id: state.chatInfo.ext.resumeId,
                    }).then(res => {
                        if (res.code == '0') {
                            res.data.resumeIntendList = res.data.ext.resumeIntendList;
                            res.data.resumeWorkList = res.data.ext.resumeWorkList;
                            res.data.resumeEducationList = res.data.ext.resumeEducationList;
                            res.data.resumeProjectList = res.data.ext.resumeProjectList;
                            state.rInfo = res.data
                        }
                    })
                }
                getMsg(); 

            })
        }

        const loopChatMsgSet = () => {
            clearTimeLoop();
            loopChatMsg({
                chatId: state.peList[state.peIndex].id,
                newestMsgId: state.mesList && state.mesList.length > 0 ? state.mesList[state.mesList.length - 1].id : 1
            }).then(res => {
                console.log('loopChatMsg==', res)
                if (res.data && res.data.length > 0) {
                    console.log('有新消息', res.data)
                    state.mesList = [...state.mesList, ...res.data];
                    nextTick(() => {
                        setbottom()
                    })
                }
                loopTime = setTimeout(() => {
                    loopChatMsgSet();
                }, 5000)
            }).catch(f => {
                loopTime = setTimeout(() => {
                    loopChatMsgSet();
                }, 5000)
            })
        }
        const clearTimeLoop = () => {
            if (loopTime) {
                clearInterval(loopTime);
                loopTime = null;
            }
        }
        onBeforeUnmount(() => {
            clearTimeLoop();
        })
        const handleClick = () => { }
        const getMsg = () => {
            listChatMsg({
                chatId: state.peList[state.peIndex].id,
                page: 1,
                pageSize: 20,
                mostFrontMsgId: ''
            }).then(res => {
                if (res.code == 0) {
                    state.mesList = res.data;
                    nextTick(() => {
                        setbottom();
                        state.udPeListLog = true;
                    })
                    loopChatMsgSet();
                }
            })
        }
        const scrollnew = (e: any) => {
            if (e.scrollTop < 10 && state.udPeListLog) {
                state.udPeListLog = false;
                let oldH = (scrollbarUl as any).value.offsetHeight;
                listChatMsg({
                    chatId: state.peList[state.peIndex].id,
                    page: 1,
                    pageSize: 20,
                    mostFrontMsgId: state.mesList.length > 0 ? state.mesList[0].id : ''
                }).then(res => {
                    if (res.code == 0) {
                        state.mesList = [...res.data, ...state.mesList];
                        state.udPeListLog = res.data.length >= 20 ? true : false;
                        nextTick(() => {
                            (scrollbarRef as any).value!.setScrollTop((scrollbarUl as any).value.offsetHeight - oldH);
                        })

                    }
                })
            }
        }

        const setbottom = () => {
            (scrollbarRef as any).value!.setScrollTop(999999999);
        }

        const sendMessage = () => {
            if (state.textarea) {
                sendChatMsg({
                    type: '1',
                    msgContent: state.textarea,
                    chatId: state.peList[state.peIndex].id,
                }).then(res => {
                    state.textarea = '';
                    clearTimeLoop();
                    loopChatMsgSet();
                })
            }
        }
        const submitY = () => {
            console.log('submitY', 1111)
        }
        const JobApplyClcik = (numType: number) => {
            sendChatMsg({
                type: numType,
                chatId: state.peList[state.peIndex].id,
                msgContent: numType == 5 ? '邀请投递简历' : '邀请交换电话'
            }).then(res => {
                clearTimeLoop();
                loopChatMsgSet();
            })
        }
        const load = () => { }
        return {
            ...toRefs(state), load, peClick, handleClick, scrollnew, setbottom, scrollbarRef, scrollbarUl, sendMessage, submitY, JobApplyClcik
        }
    }
})
